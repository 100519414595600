<template>
  <div>
    <!-- Start Breadcrump Area  -->
    <div
      class="rn-page-title-area ptb--60 bg_image bg_image--27"
      data-black-overlay="7"
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center blog-single-page-title pt--100">
              <h1 class="heading-title theme-gradient">
                Mise en route sur Google Assistant
              </h1>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <!-- Start Blog Details Area  -->
    <div class="rn-blog-details pt--60 pb--60 bg_color--1">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="inner-wrapper">
              <div class="inner">
                <div class="flex-wrap blog-single-list-wrapper d-flex">
                  <div class="thumbnail">
                    <img
                      class="w-100"
                      src="../../assets/images/pricing/assistance_login_google.jpg"
                      alt="google assistant"
                    />
                  </div>
                  <div class="content">
                    <h4 class="heading-title">
                      Pour utiliser le service sur Google assistant vous devez:
                    </h4>
                    <ul class="list-style">
                      <li>Souscrire à un pack</li>
                      <li>
                        Connectez Mes amis de confiance avec votre compte Google
                        (le même compte qui a servi à paramétrer votre
                        assistant)
                      </li>
                      <li>
                        Saisir les coordonnées ( nom , prénom téléphone fixe ou
                        portable , e-mail) une liste d’amis , proches , voisins
                        de confiance susceptibles de répondre à une demande
                        d’assistance . Nous notifierons par mail ou sms tous vos
                        contacts qu’il font parti de votre réseau de confiance.
                      </li>
                      <li>
                        Activer l’action sur Google assistant en demandant<br />
                        <em>Hey Google parler à mes amis de confiance</em>
                      </li>
                      <li>
                        Lors de cette première connexion, l’assistant vous
                        demandera de vous connecter avec votre compte Google
                        puis vous confirmera que la procédure d’initialisation
                        est terminée en vous donnant la liste des contacts de
                        confiance .
                      </li>
                    </ul>
                    <h4 class="heading-title">
                      Voilà c'est terminé !
                    </h4>
                    <p>
                      À partir de maintenant vous pouvez uliliser le service
                      quand vous voulez il suffit de dire:
                      <br />
                      <em>Hey Google, parler avecmes amis de confiance</em>
                    </p>
                    <p>
                      Vous pouvez aussi configurer
                      <a href="https://youtu.be/k9k7pWj52B0" target="_blank"
                        >une routine</a
                      >
                      afin de pouvoir dire
                      <br />
                      <em>Hey Google, je suis tombé</em>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Blog Details Area  -->

    <Footer />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>
